.opengame{
  .opengame-container{
    height: 100%;
  }
  width: 90%;
  height: 90%;
  max-width: none;
  .game{
    height: 100%;
  }
}