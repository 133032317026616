.game-card{
  @include flex(column, nowrap);
  position: relative;
  overflow: hidden;
  margin: 1em auto;
  &__name{
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-family: Smeshariki;
    font-size: 2vw;
    line-height: 1.6vw;
    text-shadow: 1px 2px 3px #aaa;
    @include media-breakpoint-down(xs) {
      font-size: 30px;
      line-height: unset;
    }
  }
  &__description{
    width: 100%;
    font-family: desc;
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    margin: auto;
    text-align: center;
    padding: 1em;
    background: rgba(255,255,255,0.9);
    height: 50%;
    top: 100%;
    display: flex;
    -webkit-transition-property: top;
    -webkit-transition-duration: 0.5s;
  }
  &__desc{
    margin: auto;
  }
  &__image{
    
    }
  &:hover{
    .game-card__description{
      top: 50%;
    }
  }
}
.game-card:nth-child(odd){
  .game-card__image{
    border-radius: 20px;
    overflow: hidden;
  } 
}
.star-rating-default, .star-rating-default a:hover, .star-rating-default a:active, .star-rating-default a:focus, .star-rating-default .current-rating {
  background: url(./alt_star.gif) left -1000px repeat-x !important;
}

.star-rating-default a:hover, .star-rating-default a:active, .star-rating-default a:focus {
  background-position: left bottom !important;
}

.star-rating-default {
  position: relative;
  /*width: 125px;*/
  height: 25px !important;
  overflow: hidden;
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
  background-position: left top !important;
}

.star-rating-default li.star {
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
  display: inline !important;
  list-style: none;
}

.star-rating-default li.star span {
  display:none;   
}

.star-rating-default a, .star-rating-default .current-rating {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -1000em !important;
  height: 25px !important;
  line-height: 25px !important;
  outline: none;
  overflow: hidden;
  border: none;
}

.star-rating-default .current-rating {
  z-index: 1;
  background-position: left center !important;
}