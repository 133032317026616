//mixin
@mixin flex($row, $wrap){
    display: inline-flex;
    flex-flow: $row $wrap;
}
@mixin transition($sec){
    transition: all $sec ease;
    -webkit-transition: all $sec ease;
    -moz-transition: all $sec ease;
    -o-transition: all $sec ease;
}