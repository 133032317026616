a:hover{
    text-decoration: none;
    opacity: 1;
  }
// form styles
.parallax-container{
    z-index: 3;
    @include flex(column, nowrap);
    height: 110vh;
    position: absolute;
    width:110%;
    top: -5%;
    left: -10%;
    align-items: center;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
        margin-top: 150px;
      }
    @media all and (orientation:portrait) {
        margin-top: 200px;
        height: 160vh; 
    }
    .portrait{
        @media all and (orientation:landscape) {
            display: none; 
        }
        background-image: url("/images/jpg/portrait.jpg");
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        width: 100%;
        height: 150vh;
        display: inline-flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        padding-left: 10%;
        margin: auto;
        .game-category{
            width: 30%;
            &__game-category-image{
                img{
                    width: 100%;
                    max-width: unset;
                }
            }
            &__game-category-name{
                font-size: 2vh;
                @include media-breakpoint-down(xs) {
                    font-size: 25px;
                  }
            }
        }
    }
    .bg{
        @include flex(column, nowrap);
        @media all and (orientation:portrait) {
            display: none; 
        }
        align-items: center;
        height: 100vh;
        &__bg-item{
            margin: auto;
            width: 110%;
            //top: unset !important;
            img{
                width: 100%;
            }
        }
        #bg0{
            top: -18vh !important;
            pointer-events: none;
        }
        #bg1{
            top: 4vh !important;
            pointer-events: none;
        }
        #bg2{
            top: 31vh !important;
            pointer-events: none;
        }
        #bg3{
            top: 35vh !important;
            pointer-events: none;
        }
        #bg4{
            top: 60vh !important;
            pointer-events: none;
        }
        #bg5{
            top: 60vh !important;
            pointer-events: none;
        }
        #bg6{
            top: 75vh !important;
            left: 15vw !important;
            a{
                color: blue;
            }
        }
        #bg7{
            top: 75vh !important;
            left: 70vw !important;
            a{
            color: yellow;
            }
        }
        #bg8{
            top: 45vh !important;
            left: 85vw !important;
            a{
            color: #ff0087;
            }
        }
        #bg9{
            top: 40vh !important;
            left: 42vw !important;
            a{
            color: #41ff06;
            }
        }
        #bg10{
            top: 50vh !important;
            left: 25vw !important;
            a{
            color: #00feec;
            }
        }
        #bg11{
            top: 60vh !important;
            left: 60vw !important;
            a{
            color: #fff;
            }
        }
        #bg12{
            top: 14vh !important;
            left: 75vw !important;
            a{
            color: #4216df;
            }
        }
        
        #bg13{
            top: 18vh !important;
            left: 15vw !important;
            a{
            color: #df16b4;
            }
        }
        #bg14{
            top: 75vh !important;
            left: 45vw !important;
            a{
            color: #8127f7;
            }
        }
        #bg15{
            top: 70vh !important;
            left: 85vw !important;
            a{
            color: #081ff1;
            }
        }
        #bg16{
            top: 25vh !important;
            left: 55vw !important;
            a{
            color: #0a2b00;
            }
        }
        #bg17{
            top: 15vh !important;
            left: 35vw !important;
            a{
            color: #ffd900;
            }
        }
    }
    .game-category{
        max-width: 200px;
        margin: auto;
        position: relative;
        z-index: 9;
        @include flex(column, nowrap);
        align-items: center;
        /*hover*/

        &:hover {
            opacity: 1;
            -webkit-animation: flash 1.5s;
            animation: flash 1.5s;
        }
        @-webkit-keyframes flash {
            0% {
                opacity: .4;
            }
            100% {
                opacity: 1;
            }
        }
        @keyframes flash {
            0% {
                opacity: .4;
            }
            100% {
                opacity: 1;
            }
        }
        /*end hover*/
        &::after{
          content: "";
          z-index: 1;
          display: block;
          background-image: url("/images/png/after.png");
          background-size: contain;
          width: 100%;;
          height: 66px;
          position: relative;
          background-repeat: no-repeat;
          top: -70px;
          background-position: 50% 100%;
        }
        a{
            @include flex(column, nowrap);
            position: relative;
            z-index: 11;
            &:hover{
                color: $white;
            }
        }
        &__game-category-image{
            @include flex(column, nowrap);
            img{
            position: relative;
            max-width: 10vw;
            width: 100%;
            z-index: 2;
            margin: auto;
            }
        }
        &__game-category-name{
            position: relative;
            width: 100%;
            z-index: 2;
            margin: auto;
            font-family: 'Smeshariki';
            text-align: center;
            font-size: 2vw;
            white-space: nowrap;
            @include media-breakpoint-down(sm) {
                font-size: 3vw
            }
            @include media-breakpoint-down(xs) {
                font-size: 30px;
                line-height: 28px;
                white-space: normal;
              }
        }
    }
}
