// Используется Bootstrap 4.
// Переопределение дефолтных значений Bootstrap, в частности отступы между колонками и брейкпоинты.

$grid-gutter-width-base: 20px;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1220px
);
// $brand-inverse: $color-base;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";


// Core CSS
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/forms";

// Utility classes
@import "node_modules/bootstrap/scss/utilities"; 