// header styles
header{
  margin-bottom: 20px;

}
.top-menu-container{
  position: relative;
  z-index: 10;
  width: 100%;
  @include flex(row, nowrap);
  @include media-breakpoint-down(sm) {
  top: -20px;
  }
  .burger{
    display: none;
    font-size: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: #0056b3;
    line-height: 20px;
    font-family: Chocolate;
    z-index: 10;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .burger-close{
    display: none;
    font-size: 25px;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 20px;
    color: #0056b3;
    line-height: 10px;
    font-family: Chocolate;
    z-index: 10;
  }
  .topmenu{
    @include flex(row, nowrap);
    width: 100%;
    font-family: Gid_VR;
    font-weight: bold;
    margin: auto;
    align-items: center;
    font-size: 2vw;
    
    @include media-breakpoint-down(sm) {
      @include flex(column, nowrap);
      font-size: 30px;
      padding-top: 20px;
      background-color: $white;
      display: none;
      position: absolute;
      left: 0;
      top:0;
    }
    .left-menu{
      @include flex(row, nowrap);
      padding: 0 2em;
      width: 40%;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        @include flex(column, nowrap);
        width: 100%;
        order: 1;
        padding: 0 1em;
      }
    }
    
    .logo-container{
      margin: 2vh;
      font-size: 2vw;
      font-family: Chocolate;
      line-height: 1vh;
      @include media-breakpoint-down(sm) {
        order: 0;
        width: 100%;
        margin: 20px auto;
        font-size: 30px;
        padding-left: 1em;
      }
    }
    .right-menu{
      @include flex(row, nowrap);
      padding: 0 2em;
      width: 40%;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        @include flex(column, nowrap);
        width: 100%;
        order: 2;
        padding: 0 1em;
      }
    }
  }
}