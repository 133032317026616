html,body{
  min-height: 100%;
  height: 100%;
  font-size: 100%;
}


// Normalize Styles
@import 'node_modules/normalize.css/normalize';
// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

//fonts
@font-face{
	font-family: 'Gid_VR';
	src: url('../fonts/Gid_VR.ttf') format('truetype');
}
@font-face{
	font-family: 'desc';
	src: url('../fonts/10200.ttf') format('truetype');
}
@font-face{
	font-family: 'Chocolate';
	src: url('../fonts/Chocolate.otf') format('opentype');
}
@font-face{
	font-family: 'Smeshariki';
	src: url('../fonts/Smeshariki.otf') format('opentype');
}

//mixin
//
// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

@import './colors';
@import './bootstrap';
@import './mixin';

// Import Modules
@import '../_modules/header/header';
@import '../_modules/footer/footer';
@import '../_modules/parallax/parallax';
@import '../_modules/screen/screen';
@import '../_modules/lastgame/lastgame';
@import '../_modules/game-card/game-card';
@import '../_modules/modal/modal';
@import '../_modules/game/game';
@import '../_modules/opengame/opengame';

.wrapper{
  position: relative;
  // height: 100%;
  min-height: 100%;
  width: 100%;
  padding-bottom: 20px;
  background-image: url("/images/jpg/portrait.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

h1{
    margin: 2vh;
    font-size: 2vw;
    font-family: Chocolate;
    color: #0056b3;
    text-align: center;
    @include media-breakpoint-down(xs) {
      font-size: 35px;
    }
}
.feedback {
  h2{
    margin: 2vh;
    font-size: 2vw;
    font-family: Chocolate;
    color: #0056b3;
    text-align: center;
    @include media-breakpoint-down(xs) {
      margin: 0px;
      font-size: 20px;
    }
  }
}
.roditelyam{ 
  .game_top{
    margin-bottom: 30px;
  } 
  .fulldesc{
    background-color: rgba(255,255,255,0.8);
    padding: 20px;
    border-radius: 20px;
    img{
      max-width: 100%;
      height: auto!important;
    }
  }
  &--item{   
    background-color: rgba(255,255,255,0.8);
    padding: 20px;
    margin: 20px 0;
    border-radius: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    @include media-breakpoint-down(md) {
      flex-flow: row wrap;
      margin: auto;
      padding: 5px;
      img{
        max-width: 100%;
      }
    }
    .introtext{
      flex: 0 0 70%;
      order: 1;
      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
      }
      .introtitle{
      font-size: 2vw;
      font-family: Chocolate;
      color: #0056b3;
      text-align: center;
      margin: 30px auto;
    @include media-breakpoint-down(xs) {
      margin: 0px;
      font-size: 20px;
      
    }
      }
      .readmore{
        margin: 30px;
        font-family: Gid_VR;
        font-weight: 400;
        font-size: 30px;
        line-height: 20px;
      }
    }
    .game-card{
      order: 0;
    }
    &:nth-child(odd) {
      .game-card{
        order: 2;
        @include media-breakpoint-down(md) {
          order: 0;
        }
      }
    }
  }
}