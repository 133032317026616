.game{
  .game_top{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .rating{
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
  .gamearea{
    height: 99vh;
    margin-top: 10px;
    #gamecanvas{
      body{
        background: transparent;
      }
    } 
  }
  .game_bottom{
    h3{
      font-family: Chocolate;
      color: #fff;
      text-align: center;
    }
  }
  .gamebottom{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px auto;
    & > div{
      flex: 0 0 30%;
      text-align: center;
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
      }
    }
  }
  .anothergame{
    @include flex(row, wrap);
    margin: 20px auto;
    width: 100%;
    justify-content: space-between;
    h3{
      font-family: Chocolate;
      color: #fff;
      text-align: center;
    }
  }
}
#breadcrumbs{
  margin-top: 20px;
  @include flex(row, nowrap);
  li{
    list-style: none;
    font-family: desc;
    font-size: 14px;
    padding: 0 0.2em;
    a:after{
      font-weight: bold;
      content: " --> ";
    }
  }
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
.pagination{
  display: flex;
  flex-flow: row wrap;
  margin: auto;
  max-width: 200px;
  justify-content: space-around;
  text-align: center;
  span{
    flex: 0 0 100%;
  }
  li{
    list-style: none;
  }
}